import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _defineProperty from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { updatePwd } from '@/api/user';
export default {
  computed: _objectSpread({}, mapGetters(['userInfo'])),
  data: function data() {
    var _this = this;

    var validateConfirmPwd = function validateConfirmPwd(rule, value, callback) {
      if (value !== _this.searchForm.password) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };

    return {
      searchForm: {
        old_password: '',
        password: '',
        pwd: ''
      },
      pwdDialog: false,
      rules: {
        old_password: [{
          required: true,
          message: '旧密码不能为空'
        }],
        password: [{
          required: true,
          message: '新密码不能为空'
        }],
        pwd: [{
          required: true,
          validator: validateConfirmPwd
        }]
      }
    };
  },
  methods: {
    transRole: function transRole(arr) {
      var roles = [];

      if (arr.length) {
        for (var i in arr) {
          roles.push(arr[i]['name']);
        }

        return roles.join(',');
      }
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      this.$refs['searchForm'].validate(function (valid) {
        if (valid) {
          updatePwd(_this2.searchForm).then(function (res) {
            console.log(res);

            _this2.$message({
              type: 'success',
              message: "修改密码成功"
            });

            _this2.pwdDialog = false;
          }).catch(function (err) {
            _this2.$message({
              type: 'warning',
              message: err
            });
          });
        }
      });
    }
  }
};